import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import {
  BedAndBreakfast,
  Organization,
  TouristAttraction,
  Website,
} from './Informations';

type Props = {
  isHome: boolean;
};

const SEO: FC<Props> = ({ isHome }) => (
  <Helmet
    defer={false}
    meta={[
      {
        name: 'viewport',
        content: 'initial-scale=1.0, user-scalable=0, width=device-width',
      },
      {
        name: 'robots',
        content:
          'index,follow,max-snippet:-1,max-image-preview:large,max-video-preview:-1',
      },
    ]}
  >
    <link rel="alternate icon" href="/favicon.ico" />
    <link rel="mask-icon" href="/mask-icon.svg" color="#ff8a01" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#292929" />
    <meta name="apple-mobile-web-app-title" content="Château de Morey" />
    <meta name="application-name" content="Château de Morey" />
    <meta name="msapplication-TileColor" content="#000000" />

    {/*<link*/}
    {/*  rel="prefetch"*/}
    {/*  as="script"*/}
    {/*  href="https://www.googletagmanager.com/gtag/js?id=UA-64287890-1"*/}
    {/*  crossOrigin=""*/}
    {/*/>*/}
    {/*<link*/}
    {/*  rel="prefetch"*/}
    {/*  as="script"*/}
    {/*  href="https://connect.facebook.net/en_US/fbevents.js"*/}
    {/*  crossOrigin=""*/}
    {/*/>*/}

    {isHome && (
      <script type="application/ld+json" data-testid="schema-website">
        {JSON.stringify(Website)}
      </script>
    )}

    {isHome && (
      <script type="application/ld+json" data-testid="schema-organization">
        {JSON.stringify(Organization)}
      </script>
    )}

    {isHome && (
      <script type="application/ld+json" data-testid="schema-bnb">
        {JSON.stringify(BedAndBreakfast)}
      </script>
    )}

    {isHome && (
      <script type="application/ld+json" data-testid="schema-tourist">
        {JSON.stringify(TouristAttraction)}
      </script>
    )}
  </Helmet>
);

export default SEO;
