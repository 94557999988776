import React, { useEffect, useRef } from 'react';
import Transition from '../Transition/Transition';
import { CSSTransition as ReactCSSTransition } from 'react-transition-group';

type Props = {
  id: string;
  ariaLabel: string;
  show: boolean;
  handleClose: () => undefined;
};

const Modal: React.FC<Props> = ({
  children,
  id,
  ariaLabel,
  show,
  handleClose,
}) => {
  const modalContent = useRef(null);

  // close the modal on click outside
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const clickHandler = ({ target }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!show || modalContent.current.contains(target)) return;
      handleClose();
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the modal if the esc key is pressed
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener('keydown', keyHandler);

    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity"
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      {/* Modal dialog */}
      <Transition
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabel}
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0 scale-95"
        enterEnd="opacity-100 scale-100"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100 scale-100"
        leaveEnd="opacity-0 scale-95"
      >
        <div
          className="bg-white overflow-auto max-w-6xl w-full max-h-full"
          ref={modalContent}
        >
          {children}
        </div>
      </Transition>
    </>
  );
};

export default Modal;
