import React, { FC, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  HeroHome,
  MODAL_CONTENT_CLASS,
} from '@kastle/ui/src/molecules/Hero/Second/MainHero';
import Button from '@kastle/ui/src/atoms/Button/Button';
import ButtonGroup from '@kastle/ui/src/atoms/Button/ButtonGroup';

const Hero: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(
        relativePath: { eq: "chateau_de_morey_nancy.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, width: 1000)
        }
      }
    }
  `);

  return (
    <HeroHome>
      <HeroHome.Content title={t('common.hero.main')} subTitle="">
        <ButtonGroup>
          <Button
            as={Link}
            to={t('common.hero.book.link')}
            data-testid="book-link"
            aria-label={t('common.hero.book.name')}
          >
            {t('common.hero.book.name')}
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            as={Link}
            to={t('common.hero.events.link')}
            data-testid="event-link"
            aria-label={t('common.hero.events.name')}
          >
            {t('common.hero.events.name')}
          </Button>
        </ButtonGroup>
      </HeroHome.Content>
      <HeroHome.Image onClick={() => setOpen(true)}>
        <GatsbyImage
          image={data?.backgroundImage.childImageSharp?.gatsbyImageData}
          style={{
            top: '0',
            left: '0',
            right: '0',
            width: '100%',
            height: '100%',
          }}
          objectFit="cover"
          className="mx-auto"
          alt={t('common.hero.main')}
        />
      </HeroHome.Image>
      <HeroHome.Modal show={open} onClose={() => setOpen(false)}>
        {open ? (
          <iframe
            className={MODAL_CONTENT_CLASS}
            src="https://www.youtube.com/embed/CqO68Pjl2m8?color=white"
            title="Video"
            allowFullScreen
          />
        ) : null}
      </HeroHome.Modal>
    </HeroHome>
  );
};

export default Hero;
