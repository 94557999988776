import React, { FC } from 'react';
import MdxRenderer from '@kastle/mdx/src/components/Layout/MdxRenderer';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import HeaderComponent from '../../../../components/Header/Header';
import FooterComponent from '../../../../components/Footer/Footer';
import withI18next from '../../../../i18n/I18n';
import { PageContext } from '../../../../types';
import ThemeContextProvider from '../../../../../../components/src/components/Theme/Theme';
import Hero from '../../../../components/Hero/Hero';
import LatestArticles from '../../../../sections/LatestArticles';
import PageSEO from '../../../../../../mdx/src/components/SEO/Page';
import SEO from '../../../../components/SEO/SEO';

type Props = {
  pageContext: PageContext;
  location: Location;
  path: string;
  uri: string;
};

const isHomePage = (path: string): boolean => {
  if (
    path === '/' ||
    path === '/de/' ||
    path === '/en/' ||
    path === '/es/' ||
    path === '/nl/'
  ) {
    return true;
  }
  return false;
};

const DefaultLayout: FC<Props> = ({ children, pageContext, uri }) => {
  const isHome = isHomePage(pageContext.slug);
  return (
    <ThemeContextProvider lightColor="#ffffff" darkColor="#000000">
      <SEO isHome={isHome} />
      <PageSEO
        lang={pageContext.langKey}
        title={pageContext?.frontmatter?.title}
        description={pageContext?.frontmatter?.description}
        path={uri}
      />
      <div className="font-secondary ">
        <HeaderComponent
          fillEmptySpace={!isHome}
          transparentMode={isHome}
          pageContext={pageContext}
        />
        {isHome ? <Hero /> : null}
        {isHome ? (
          <div className="break-words">
            <MdxRenderer>{children}</MdxRenderer>
          </div>
        ) : (
          <Section type={SectionType.Secondary}>
            <div className="break-words ArticleDetail__max-width">
              <MdxRenderer>{children}</MdxRenderer>
            </div>
          </Section>
        )}
        {!isHome ? <LatestArticles /> : null}
        <FooterComponent pageContext={pageContext} />
      </div>
    </ThemeContextProvider>
  );
};

export default withI18next(DefaultLayout);
