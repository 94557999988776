import React from 'react';
import { BottomRuban } from '../../Illustration/BottomRuban';
import { PlayIcon } from '../../../atoms/Icons/Play';
import { SubText } from '../../../atoms/SubText/SubText';
import Heading from '../../../atoms/Heading/Heading';
import { HeadingTypes } from '../../../atoms/Heading/Heading.types';
import Modal from '../../../atoms/Modal/Modal';

type HeroContentProps = {
  title: string;
  subTitle: string;
};

export const HeroHomeContent: React.FC<HeroContentProps> = ({
  children,
  title,
  subTitle,
}) => (
  <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
    <Heading as={HeadingTypes.h1} className="mb-4" data-aos="fade-up">
      {title}
    </Heading>
    <SubText data-aos="fade-up" data-aos-delay="200">
      {subTitle}
    </SubText>
    <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
      {children}
    </div>
  </div>
);

const imageProps = {
  className: 'mx-auto',
  width: '1024',
  height: '504',
};

type HeroImageProps = {
  onClick?: () => undefined;
};

export const HeroHomeImage: React.FC<HeroImageProps> & {
  imageProps: Record<string, any>;
} = ({ children, onClick }) => {
  return (
    <div>
      <div
        className="relative flex justify-center items-center"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {children}
        <a
          className="absolute group cursor-pointer"
          aria-controls="modal"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={onClick}
        >
          <PlayIcon />
        </a>
      </div>
    </div>
  );
};

HeroHomeImage.imageProps = imageProps;

type HeroModalProps = {
  show: boolean;
  onClose: () => undefined;
};

const HeroModal: React.FC<HeroModalProps> = ({ show, children, onClose }) => (
  <Modal
    id="modal"
    ariaLabel="modal-headline"
    show={show}
    handleClose={onClose}
  >
    <div className="relative pb-9/16">{children}</div>
  </Modal>
);

export const MODAL_CONTENT_CLASS = 'absolute w-full h-full';

export const HeroHome: React.FC & {
  Image: React.FC<HeroImageProps>;
  Content: React.FC<HeroContentProps>;
  Modal: React.FC<HeroModalProps>;
} = ({ children }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
      <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">{children}</div>
    </div>
  );
};

HeroHome.Image = HeroHomeImage;
HeroHome.Content = HeroHomeContent;
HeroHome.Modal = HeroModal;
